// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-steps-steps-js": () => import("/Users/luketongue/Documents/Syndicut/SkyCurfew/Gatsby/src/templates/steps/steps.js" /* webpackChunkName: "component---src-templates-steps-steps-js" */),
  "component---src-pages-404-js": () => import("/Users/luketongue/Documents/Syndicut/SkyCurfew/Gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-howtoplay-js": () => import("/Users/luketongue/Documents/Syndicut/SkyCurfew/Gatsby/src/pages/howtoplay.js" /* webpackChunkName: "component---src-pages-howtoplay-js" */),
  "component---src-pages-index-js": () => import("/Users/luketongue/Documents/Syndicut/SkyCurfew/Gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-theprize-js": () => import("/Users/luketongue/Documents/Syndicut/SkyCurfew/Gatsby/src/pages/theprize.js" /* webpackChunkName: "component---src-pages-theprize-js" */),
  "component---src-pages-thestory-js": () => import("/Users/luketongue/Documents/Syndicut/SkyCurfew/Gatsby/src/pages/thestory.js" /* webpackChunkName: "component---src-pages-thestory-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/luketongue/Documents/Syndicut/SkyCurfew/Gatsby/.cache/data.json")

